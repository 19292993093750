<template>
  <div>
    <!--<Period />-->
    <Dynamic :hide-dealer="true" />
  </div>
</template>

<script>
//import Period from "@/components/reports/Period";

import Dynamic from "@/components/reports/Dynamic"
export default {
  name: "Reports",
  components: {
    Dynamic,
    //Period
  },
}
</script>

<style scoped></style>
